
.MuiSlider-markLabel{
  color:#fff;
  top: -15px!important;
}
.MuiSlider-valueLabel{
  display: none;
}
.MuiSlider-valueLabelOpen{
  display: none;
}