.active-item-card .css-1o83ggv-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1o83ggv-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-1o83ggv-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0 0.5rem!important;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
  border-color: #47BE42!important;
} */

.css-usylml-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #47BE42!important;
}