.ck-style .ck-modal-container  {
    z-index: 1300!important;
}

.css-1mpfsae {
    width: 100%;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    position: absolute!important;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    z-index: 999!important;
}

#item-card-container {
    transition: all ease-in 0.15s;
    padding-bottom: 100px;
}

.MuiLoadingButton-loadingIndicator {
    color: rgb(255 255 255)!important;
}

.darkTheme .MuiLoadingButton-loadingIndicator {
    color: rgb(38 37 37 / 46%)!important;
    background: rgb(15, 237, 178);
}

.bm-banner {
    top: 14px!important;
    bottom: auto!important;
}

[type=file]{
    display:none;
}
