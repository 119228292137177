.clareza {
  background-color: rgba(68, 137, 255, 1);
}
.concisao {
  background-color: rgba(70, 189, 159, 1);
}
.forca {
  background-color: rgba(136, 90, 189, 1);
}
.originalidade {
  background-color: rgba(255, 191, 73, 1);
}
.formatacao {
  background-color:#4CD7E4;
}
.correcao {
  background-color: rgba(244, 67, 54, 1);
}
.gramatica {
  background-color: rgba(255, 153, 0, 1);
}
.ortografia {
  background-color: rgba(244, 67, 54, 1);
}
.pontuacao {
  background-color: #4588FF;
}